<template>

    

    <div style="padding:20px;">

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>
                <div style="">
                    <div style="margin-bottom:20px;">
                            <h2 v-if="artigo && artigo.length > 0" >{{artigo[0].titulo}}</h2>
                    </div>
                </div>
            </v-card-text>               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>
                    <div v-html="artigo[0].texto" style="margin-bottom:20px;">
                    </div>
            </v-card-text>               
        </v-card>



    </div> 

    
</template>

<script>

import api from '../services/api'
const config = { headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`} };

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            artigo:[],
        }
    },
    watch: {
        
    },
    methods:{
        buscarArtigos(){
            let body = {
                id:this.$route.params.idartigo
            }
            api().post('/artigo/buscarArtigos', body, config).then(resp=>{
                this.artigo = resp.data
            })
        },
    },

    
    async mounted(){
        this.buscarArtigos()
    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>